import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useEffect } from "react";
import "./style/userstatus.css";
const Userstatus = () => {
  const [user, SetUser] = useState("");
  const [admins, setAdmins] = useState("");
  const [managers, setManagers] = useState("");
  useEffect(() => {
    userList();
    adminss("admins");
    managerss("managers");
  }, []);
  const series = [admins, managers, user];
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: [`Admin (${admins})`, `Manager (${managers})`, `Workers (${user})`],
    colors: ["rgb(81, 86, 190)", "rgb(168, 170, 218)", "rgb(119, 122, 202)"],

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  const userList = () => {
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/count/users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((res) => SetUser(res.data.users));
  };
  const adminss = async (role) => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/count/${role}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAdmins(res.data.admins);
    } catch (error) {}
  };

  const managerss = async (role) => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/count/${role}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setManagers(res.data.managers);
    } catch (error) {}
  };

  return (
    <div id="chart" className="user_status">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={"70%"}
      />
    </div>
  );
};

export default Userstatus;
