import React from "react";

import { useEffect } from "react";

import { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import TableContainer from "@mui/material/TableContainer";

import Paper from "@mui/material/Paper";
import Appbar from "../compoents/Appbar/Appbar";
import Dashboard from "../compoents/DashBoard/Dashboard";
import "./styles/useractivities.css";
function UserActivityReport() {
  const { userid } = useParams();
  useEffect(() => {
    getuserReport();
  }, [userid]);
  const [activity, setActivity] = useState([]);

  const [errors, setErrors] = useState("");
  const getuserReport = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/scenarioProgress/${userid}`
      );
      if (res.data.length <= 0) {
        return setErrors("No records found");
      }
      setActivity(res.data);
    } catch (error) {
      setErrors(error.data.mesage);
    }
  };
  return (
    <div style={{ display: "flex", backgroundColor: "#fff" }}>
      <div style={{ backgroundColor: "#5156be" }}>
        <Dashboard />
      </div>
      <div style={{ width: "100%" }}>
        <Appbar />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <TableContainer component={Paper}>
            <Table className="userlistTable_table_useract">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th> Day & Time</Th>

                  <Th>Progress</Th>
                  <Th>Time spent in platform</Th>
                </Tr>
              </Thead>

              <Tbody>
                {errors ? (
                  <Tr>
                    <Td style={{ color: "red" }}>{errors}</Td>{" "}
                  </Tr>
                ) : (
                  activity.map((activity, index) => (
                    <Tr>
                      <Td>{index + 1}</Td>
                      <Td>{activity.createdAt}</Td>

                      <Td>{activity.progress}%</Td>
                      <Td>
                        {`${Math.floor(activity.timespent / 60)} mins `}
                        {activity.timespent -
                          Math.floor(activity.timespent / 60) * 60}
                        sec
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
}

export default UserActivityReport;
