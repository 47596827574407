import React, { useState } from "react";

import { FaBars, FaHome } from "react-icons/fa";
import LogoutIcon from "@mui/icons-material/Logout";
import ListIcon from "@mui/icons-material/List";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import GroupIcon from "@mui/icons-material/Group";
import { NavLink } from "react-router-dom";

import "./Sidebarr.css";
const id = localStorage.getItem("id");
const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/Userdetails",
      name: "Dashboard",
      icon: <FaHome style={{ marginTop: "-9px", fontSize: "20px" }} />,
    },
    {
      path: "/Userlist",
      name: "User List",
      icon: <ListIcon style={{ marginTop: "-9px", fontSize: "20px" }} />,
    },
    {
      path: "/Addnewuser",
      name: "Add New User",
      icon: (
        <PersonAddAltIcon style={{ marginTop: "-9px", fontSize: "20px" }} />
      ),
    },
    {
      path: "/OnlineUsers",
      name: "Online Users",
      icon: <GroupIcon style={{ marginTop: "-9px", fontSize: "20px" }} />,
    },
    // {
    //   path: `/profile/${id}`,
    //   name: "Profile",
    //   icon: <PersonIcon style={{ marginTop: "-9px", fontSize: "20px" }} />,
    // },
    {
      path: "/Prefrences",
      name: "Prefrences",
      icon: <ListIcon style={{ marginTop: "-9px", fontSize: "20px" }} />,
    },

    {
      path: "/",
      name: "Logout",
      icon: (
        <LogoutIcon
          style={{ marginTop: "-9px", fontSize: "20px" }}
          onClick={() => {
            localStorage.clear();
          }}
        />
      ),
    },
  ];
  return (
    <div className="container slidebar_container" style={{ padding: "0" }}>
      <div
        style={{ width: isOpen ? "200px" : "70px", zIndex: 2 }}
        className="sidebar"
      >
        <div className="top_section">
          <h1
            style={{ display: isOpen ? "block" : "none", fontSize: "15px" }}
            className="logo"
          >
            MXR
          </h1>
          <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
            <FaBars onClick={toggle} />
          </div>
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
