import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";

import Form from "react-bootstrap/Form";

import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import { Link } from "react-router-dom";

function EditUser({ data: userProp, handleEdit, setErrorMessage, setMessage }) {
  const [show, setShow] = useState(false);

  const [user, setUser] = useState({ ...userProp });
  const addUser = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    const res = await fetch(
      `${process.env.REACT_APP_BASE_URL}/edit/${user._id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          firstname: user.firstname,
          lastname: user.lastname,
          username: user.username,
          email: user.email,
          role: user.role,
        }),
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setErrorMessage(null);
        setMessage(res.message);
      })
      .catch((error) => {
        setMessage(null);
        setErrorMessage(res.error.message);
      });

    // userList();

    setShow(false);
    handleEdit();
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const { firstname, lastname, username, email, role } = user;

  return (
    <div>
      <div>
        <Link to="#" onClick={() => setShow(true)}>
          <button style={{ border: "0.2px", backgroundColor: "transparent" }}>
            <CreateOutlinedIcon
              style={{ border: "1px solid", padding: "2px" }}
            />
          </button>
        </Link>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textTransform: "capitalize",
              letterSpacing: ".2rem",
              fontWeight: 500,
              fontSize: "1rem",
            }}
          >
            Userdetails
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={addUser}
            style={{ padding: "2rem", borderRadius: ".375rem" }}
          >
            <label>First Name</label>
            <input
              className="form-control mb-4"
              type="text"
              name="firstname"
              value={firstname}
              onChange={handleChange}
              required
            />
            <label>Last Name</label>
            <input
              className="form-control mb-4"
              type="text"
              name="lastname"
              value={lastname}
              onChange={handleChange}
              required
            />

            <label>Username</label>
            <input
              className="form-control mb-4"
              type="text"
              name="username"
              value={username}
              onChange={handleChange}
              required
              readOnly="readOnly"
            />

            <label>Role</label>
            <Form.Select
              aria-label="Defaul select example"
              type="text"
              name="role"
              value={role}
              required
              onChange={handleChange}
            >
              <option>select Type</option>
              <option value="worker">worker</option>
              <option value="manager">Manager</option>
              <option value="admin">admin</option>
            </Form.Select>
            <label>Email</label>
            <input
              className="form-control mb-4"
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              required
            />

            <Button
              style={{
                textAlign: "right",
                width: " 100%",
                color: "#ffff",
                fontSize: "1rem",
                borderRadius: ".375rem",
                backgroundColor: "rgb(81, 86, 190)",
                textTransform: "capitalize",
              }}
              type="submit"
              className=" btn-warning"
              onClick={() => setShow(false)}
              disableRipple
            >
              Update
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default EditUser;
