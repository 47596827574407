import React, { useState, useEffect } from "react";
import axios from "axios";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Dashboard from "../compoents/DashBoard/Dashboard";
import Appbar from "../compoents/Appbar/Appbar";
import { Box, Button } from "@mui/material";

import swal from "sweetalert";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { userList } from "../services/api";
import "../compoents/test.css";
import EditUser from "../Pages/EditUser";
import EditPasswordByAdmin from "../Pages/EditPasswordByAdmin";
import { Link } from "react-router-dom";
function Userlist() {
  const [edit, setEdit] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    document.title = "User List";
    userData();
  }, []);
  const userData = async () => {
    let res = await userList();

    setData(res.data);
  };

  const deletuser = async (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,

      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const token = localStorage.getItem("token");

        try {
          let response = await axios.delete(
            `${process.env.REACT_APP_BASE_URL}/user/delete/${id}`,
            {
              method: "DELETE",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setErrorMessage(null);
          setMessage(response.data.message);
        } catch (error) {
          setMessage(null);
          setErrorMessage(error.response.data.message);
        }

        userData();
      }
    });
  };
  const handleUp = () => {
    setEdit(true);
  };
  const handleEdit = () => {
    setEdit(false);
    userData();
  };

  return (
    <div>
      <div style={{ display: "flex", backgroundColor: "#fff" }}>
        <div style={{ backgroundColor: "#5156be" }}>
          <Dashboard />
        </div>
        <div className="appbar1" style={{ width: "100%" }}>
          <Appbar />
          <Box m={2.5} display="flex">
            {message ? <span className="alert_green">{message}</span> : null}
            {errorMessage ? (
              <span className="alert_danger">{errorMessage}</span>
            ) : null}
          </Box>

          <div className="userTableMain">
            <div className="userlistTable"></div>
            <div className="userList_heading">
              <div className="userList_heading_span">
                <span className="userList_heading_span_conten">user list</span>
                <Link to="/Addnewuser">
                  <Button
                    variant="outlined"
                    startIcon={<PersonAddIcon />}
                    sx={{
                      backgroundColor: "black",
                      color: "#fff",
                      fontSize: ".6rem",
                      textTransform: "capitalize",
                    }}
                    className="userList_heading_span_btn"
                  >
                    add new user
                  </Button>
                </Link>
              </div>

              <Table className="userlistTable_table">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>first name</Th>
                    <Th>Last name</Th>
                    <Th>username</Th>
                    <Th>email</Th>

                    <Th>Role</Th>
                    <Th>Delete</Th>
                    <Th>Edit</Th>
                    <Th>change Password</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((user, index) => (
                    <Tr key={index}>
                      <Td>{index}</Td>
                      <Td>{user.firstname}</Td>
                      <Td>{user.lastname}</Td>
                      <Td>{user.username}</Td>
                      <Td>{user.email}</Td>

                      <Td>{user.role}</Td>
                      <Td>
                        <DeleteIcon
                          onClick={() => deletuser(user._id)}
                          color="error"
                          style={{ border: "1px solid", padding: "2px" }}
                        ></DeleteIcon>
                      </Td>
                      <Td>
                        <Button>
                          <EditUser
                            data={user}
                            handleUp={handleUp}
                            handleEdit={handleEdit}
                            edit={edit}
                            fetchData={userList}
                            setErrorMessage={setErrorMessage}
                            setMessage={setMessage}
                          />
                        </Button>
                      </Td>
                      <Td>
                        <Button>
                          <EditPasswordByAdmin
                            data={user}
                            handleUp={handleUp}
                            handleEdit={handleEdit}
                            edit={edit}
                            fetchData={userList}
                            setErrorMessage={setErrorMessage}
                            setMessage={setMessage}
                          />
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Userlist;
