import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { FaBars } from "react-icons/fa";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import AccountCircle from "@mui/icons-material/AccountCircle";

import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";

import "./appbar.css";
import { Outlet, Link, Navigate } from "react-router-dom";
import { Button } from "@mui/material";

export default function Appbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const id = localStorage.getItem("id");
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ marginTop: "30px" }}
    >
      <Button onClick="" component={Link} to={`/profile/${id}`}>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Button>
      <MenuItem
        onClick={() => {
          localStorage.clear();
          handleMenuClose();
        }}
      >
        <Link to="/" style={{ textDecoration: "none", color: "black" }}>
          {" "}
          Logout
        </Link>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 5 new notifications"
          color="inherit"
        >
          <Badge badgeContent={5} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          sx={{ marginTop: "-200px" }}
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  const name = localStorage.getItem("name");
  const Id = localStorage.getItem("id");
  return (
    <Box sx={{ flexGrow: 1, marginBottom: "70px" }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          marginBottom: "30px",
          zIndex: 1,
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <div className="appbars">
              <FaBars onClick={toggle} fontSize="large/" />
            </div>
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              aria-label="show 5 new notifications"
              color="inherit"
            >
              <Badge badgeContent={5} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box>
            <p
              style={{ margin: "10px", fontSize: ".8rem" }}
              className="profile_name"
            >
              {name}
            </p>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Outlet />
      <div
        style={{
          display: isOpen ? "flex" : "none",
          margin: "auto",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,

          justifyContent: "flex-start",
          zIndex: 3,
        }}
      >
        <div style={{}} className="appbarSidebar_toggle">
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
            color="#fff"
            mb={2}
          >
            <span>MXR</span>
            <FaBars onClick={toggle} fontSize="large/" />
          </Box>
          <Link to="/Userdetails">
            <Button
              onClick={() => {
                toggle();
              }}
            >
              Dashboard
            </Button>
          </Link>
          <Link to="/userlist">
            <Button
              onClick={() => {
                toggle();
              }}
            >
              User List
            </Button>
          </Link>
          <Link to="/addnewuser">
            <Button
              onClick={() => {
                toggle();
              }}
            >
              Addnewuser
            </Button>
          </Link>
          <Link to="/onlineUsers">
            <Button
              onClick={() => {
                toggle();
              }}
            >
              OnlineUsers
            </Button>
          </Link>
          {/* <Link to={`/profile/${Id}`}>
            <Button
              onClick={() => {
                toggle();
              }}
            >
              {" "}
              Profile
            </Button>
          </Link> */}
          <Link to="/Prefrences">
            <Button
              onClick={() => {
                toggle();
              }}
            >
              {" "}
              Prefrences
            </Button>
          </Link>
          <Link to="/">
            <Button
              onClick={() => {
                localStorage.clear();
                toggle();
              }}
            >
              Logout
            </Button>
          </Link>
        </div>
      </div>
    </Box>
  );
}
