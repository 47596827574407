import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useEffect } from "react";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./style/scenario.css";
import { userList } from "../../services/api";
import { Link } from "react-router-dom";
const Scenarioprogress = () => {
  const [userid, setUserId] = useState("");
  const [averageTime, setAverageTime] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [progres, setProgres] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    scenarioProgress();
  }, [userid]);
  useEffect(() => {
    userDataList();
  }, []);
  const scenarioProgress = async () => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/sumscenario`,
        {
          userid: userid,
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAverageTime(res.data.avgTime);
      setTotalTime(res.data.totalTime);
      setProgres(res.data.progress);
    } catch (error) {
      setAverageTime("00:00:00");
      setTotalTime("00:00:00");
      setProgres("0");
    }
  };

  const userDataList = async () => {
    let res = await userList();
    setUserId(res.data[0]._id);
    setData(res.data);
  };

  const series = [progres];
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
      offsetY: -10,
    },

    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "16px",
            color: undefined,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: "22px",
            color: undefined,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: ["Median Ratio"],
    colors: ["rgb(81, 86, 190)"],
  };

  return (
    <div
      id="chart"
      style={{ display: "flex", justifyContent: "space-between" }}
      className="scenario"
    >
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={250}
        style={{ flex: 1 }}
      />

      <div className="scenaroio_progress">
        <select
          john="updateTheme"
          style={{
            fontSize: ".75rem",
            borderRadius: "4px",
            border: "2px solid #d9cfcf",
            textTransform: "capitalize",
            fontWeight: "400",
          }}
          defaultValue={userid}
          onChange={(e) => {
            setUserId(e.target.value);
          }}
        >
          {data.map((user, index) => (
            <React.Fragment key={index}>
              <option value={user._id}>{user.username}</option>
            </React.Fragment>
          ))}
        </select>
        <div className="scenaio_time">
          <div className="scenaio_time_text_btn">
            <p>Total Time Spent in VR</p>

            <span>{totalTime}</span>
            <p>Average Time Spent in VR</p>
            <span>{averageTime}</span>
          </div>
          <Link
            to={`/activity-report/${userid}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              className="scenario_view_report_btn"
              variant="contained"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              disableRipple
            >
              view report
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Scenarioprogress;
