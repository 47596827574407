import { styled, Box } from "@mui/material";
import React from "react";
import Login from "./Login/Login";
import Slider from "./Slider/Slider";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down(1050)]: {
    flexDirection: "column",
    justifyContent: "center",
    padding: "0",
  },
}));
const Home = () => {
  return (
    <>
      <StyledBox>
        <Login />
        <Slider />
      </StyledBox>
    </>
  );
};

export default Home;
