import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Dashboard from "../compoents/DashBoard/Dashboard";
import { registerSchema } from "../compoents/schema/index";
import Appbar from "../compoents/Appbar/Appbar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import axios from "axios";
import "./styles/adduser.css";

const initialValues = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  password: "",
  role: "",
};
function Addnewuser() {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    document.title = "Add User";
  });
  const navigate = useNavigate();
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      const { firstname, lastname, username, email, password, role } = values;
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/register`,
          {
            username: username,
            firstname: firstname,
            lastname: lastname,
            email: email,
            password: password,
            role: role,

            method: "POST",

            headers: {
              "Content-Type": "Application/json",
            },
          }
        );
        console.log(response.data.data);
        if (response.data.status === "sucess") return navigate("/Userlist");
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    },
  });
  const admin = localStorage.getItem("role");
  return (
    <div className="adduser_main">
      <div style={{ backgroundColor: "#5156be" }}>
        <Dashboard />
      </div>
      <div style={{ width: "100%" }}>
        <Appbar />
        <div className="adduser_main_content">
          <div className="adduser_main_content_user">
            <p>Add New User</p>
          </div>

          <div className="adduser_main_content_admin">
            <p>
              {admin} <KeyboardArrowRightIcon />
            </p>
            <p>Add New</p>
          </div>
        </div>
        {errorMessage ? (
          <p className="alert_danger" style={{ textAlign: "center" }}>
            {errorMessage}
          </p>
        ) : null}

        <div className="adduser_comp">
          <div className="adduser_comp_form">
            <Form onSubmit={handleSubmit}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
                onSubmit={handleSubmit}
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  First name
                </Form.Label>
                <Col sm="12" lg="5">
                  {errors.firstname && touched.firstname ? (
                    <p className="alert_danger">{errors.firstname}</p>
                  ) : null}
                  <input
                    type="text"
                    placeholder="Enter your First Name"
                    value={values.firstname}
                    name="firstname"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  Last name
                </Form.Label>
                <Col sm="12" lg="5">
                  {errors.lastname && touched.lastname ? (
                    <p className="alert_danger">{errors.lastname}</p>
                  ) : null}
                  <input
                    type="text"
                    placeholder="Enter your Last Name"
                    value={values.lastname}
                    name="lastname"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  Username
                </Form.Label>
                <Col sm="12" lg="5">
                  {errors.username && touched.username ? (
                    <p className="alert_danger">{errors.username}</p>
                  ) : null}
                  <input
                    type="text"
                    placeholder="Enter your Username"
                    value={values.username}
                    name="username"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  Email
                </Form.Label>
                <Col sm="12" lg="5">
                  {errors.email && touched.email ? (
                    <p className="alert_danger">{errors.email}</p>
                  ) : null}
                  <input
                    type="email"
                    placeholder="Enter your Email"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  Password
                </Form.Label>
                <Col sm="12" lg="5">
                  {errors.password && touched.password ? (
                    <p className="alert_danger">{errors.password}</p>
                  ) : null}
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                    style={{ color: "#495057" }}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="2" style={{ fontSize: ".9rem" }}>
                  Role
                </Form.Label>
                {errors.role && touched.role ? (
                  <p className="alert_danger">{errors.role}</p>
                ) : null}

                <Col sm="12" lg="5">
                  <Form.Select
                    aria-label="Defaul select example"
                    type="text"
                    name="role"
                    onChange={handleChange}
                    style={{
                      fontSize: ".8rem",
                      textTransform: "lowercase",
                      color: "#495057",
                    }}
                  >
                    <option>select Role</option>
                    <option value="worker">worker</option>
                    <option value="manager">manager</option>
                    <option value="admin">admin</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#564ab1",
                  marginTop: "-277px",
                  border: "none",
                  fontSize: ".8rem",
                }}
              >
                Submit form
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Addnewuser;
