import { styled, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import "./forget.css";
import { useFormik } from "formik";
import { forgetSchema } from "../schema";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LOGO from "../../images/mxr.svg";
import LockResetIcon from "@mui/icons-material/LockReset";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    width: "100%",
    gap: "30px",
  },
}));
const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > :nth-of-type(1)": {
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
}));
const StyledMain = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  padding: "20px",
}));

const initialValues = {
  email: "",
};
const ForgetPassword = () => {
  const [message, setMessage] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  useEffect(() => {
    document.title = "Forget Password";
  });

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues,
    validationSchema: forgetSchema,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/forgetpassword`,
          {
            email,

            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setErrorMessage(null);
        setMessage(response.data.message);
        console.log(response.status);
        if (response.status === 200) {
          return navigate("/emailsend");
        }
      } catch (error) {
        setMessage(null);
        setErrorMessage(error.response.data.message);
      }
    },
  });

  const navigate = useNavigate();
  return (
    <div>
      <StyledBox
        className="form"
        style={{ textTransform: "capitalize", fontWeight: "500" }}
      >
        <StyledMain style={{ borderRadius: "8px" }}>
          {message ? <span className="alert_green">{message}</span> : null}
          {errMessage ? (
            <span className="alert_danger">{errMessage}</span>
          ) : null}
          <div className="log_img">
            <img src={LOGO} alt="logo_img" />
          </div>
          <LockResetIcon fontSize="large" />
          <div className="forget_heading">
            <span>forget password?</span>
          </div>
          <div className="forget_sub-heading">
            <p>no worries we will send you rest instructions</p>
          </div>

          <StyledForm className="formforget">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                className="mb-30"
              >
                {errors.email && touched.email ? (
                  <p className="alert_danger">{errors.email}</p>
                ) : null}
                <Label for="email">Email</Label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="enter your email"
                  value={values.password}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                className="form-button"
                disableElevation
              >
                reset password
              </Button>
            </Form>
          </StyledForm>

          <Button
            onClick={() => navigate("/")}
            startIcon={<ArrowBackIcon fontSize="small" />}
            disableElevation
          >
            back to login
          </Button>
        </StyledMain>
      </StyledBox>
    </div>
  );
};

export default ForgetPassword;
