import { Box, styled } from "@mui/material";
import back from "../../images/back.jpg";

export const ContainerBox = styled(Box)(({ theme }) => ({}));
export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "40px",
}));

export const LogoBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "5px",
}));

export const InputBox = styled(Box)(({ theme }) => ({}));
export const FormBox = styled(Box)(({ theme }) => ({
  width: "400px",
  padding: "20px",
  [theme.breakpoints.down(360)]: {
    width: "250px",
  },
}));

export const SliderBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${back})`,
  backgroundSize: "100% 100%",
  width: "100%",
}));
