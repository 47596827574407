import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/mxr.svg";
import { Container, LogoBox, InputBox, FormBox } from "../style/login.js";
import { Box, Button } from "@mui/material";
import { useFormik } from "formik";
import "./login.css";
import { loginSchema } from "../schema";
import { useState, useEffect } from "react";
import React from "react";

const initialValues = {
  username: "",
  password: "",
};
function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    const auth = localStorage.getItem("token");
    document.title = "Login";
    if (auth) {
      navigate("/Userdetails");
    }
  }, []);
  const [errorMessage, setErrorMessage] = useState("");
  const [psw, setPasw] = useState(false);

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const { username, password } = values;
      try {
        let response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/applogin`,
          {
            method: "POST",
            username,
            password,
          }
        );

        const token = response.data.data.token;
        const id = response.data.data.id;
        const role = response.data.data.userType;
        const name = response.data.data.name;

        if (response.data.data.auth) {
          localStorage.setItem("token", token);
          localStorage.setItem("id", id);
          localStorage.setItem("role", role);
          localStorage.setItem("name", name);

          navigate("/Userdetails");
        }
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    },
  });
  return (
    <Container className="login_form">
      <LogoBox className="logo">
        <div className="logo_img">
          <img src={logo} alt="logo_img" />
        </div>
      </LogoBox>
      <div className="header">
        <h6>Welcome Back!</h6>
      </div>
      {errorMessage ? (
        <div className="alert">
          <p className="alert_danger">{errorMessage} </p>
        </div>
      ) : null}

      <FormBox className="form">
        <form onSubmit={handleSubmit}>
          <InputBox className="form_input_login">
            <label htmlFor="username">username</label>
            <input
              type="text"
              name="username"
              value={values.username}
              onChange={handleChange}
              placeholder="username"
            />
          </InputBox>
          {errors.username && touched.username ? (
            <p className="alert_danger">{errors.username}</p>
          ) : null}
          <InputBox className="form_input_login">
            <div className="forgetpassword">
              <label htmlFor="password">password</label>
              <label htmlFor="forget_password">
                <Link
                  to="/forgetpassword"
                  style={{ textDecoration: "none", fontSize: ".8rem" }}
                >
                  forget password
                </Link>
              </label>
            </div>
            <div className="showBtn">
              <input
                type={psw ? "text" : "password"}
                name="password"
                placeholder="password"
                value={values.password}
                onChange={handleChange}
              />
              <Box className="showHide" onClick={() => setPasw(!psw)}>
                <VisibilityIcon color="none" fontSize="16px" />
              </Box>
            </div>
          </InputBox>
          {errors.password && touched.password ? (
            <p className="alert_danger">{errors.password}</p>
          ) : null}
          <div className="checkBox">
            <input type="checkbox" id="remember" name="remember-me" value="" />
            <span style={{ fontSize: ".8rem", color: "#495057" }}>
              {" "}
              Remember me
            </span>
          </div>
          <div className="login">
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgb(81, 86, 190)",
                textTransform: "capitalize",
                fontSize: ".8rem",
              }}
              size="small"
              type="submit"
            >
              Log In
            </Button>
          </div>
        </form>
      </FormBox>

      <div className="copyright">
        <p>
          &copy; 2022 . Powered By{" "}
          <strong style={{ fontWeight: 500, color: "#495057" }}>
            Meta Extended Reality
          </strong>
        </p>
      </div>
    </Container>
  );
}

export default Login;
