import React, { useState } from "react";
import { useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import { onlineUserList } from "../../../services/api";

const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/continents/north-america.json";

const MapChart = () => {
  useEffect(() => {
    onlineUserDataList();
  }, []);
  const [data, setData] = useState([]);
  const onlineUserDataList = async () => {
    let res = await onlineUserList();
    setData(res.data);
  };

  return (
    <>
      <ComposableMap
        projection="geoAlbers"
        projectionConfig={{
          scale: 330,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
              />
            ))
          }
        </Geographies>

        {data.map((log) => (
          <>
            <Marker coordinates={[log.latitude, log.longitude]} fill="#777">
              <text textAnchor="middle" fill="#289EC4">
                {log.username}
              </text>
              <circle r={10} fill="#F00" stroke="#fff" strokeWidth={2} />
            </Marker>
          </>
        ))}
      </ComposableMap>
    </>
  );
};

export default MapChart;
