import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

import LoginIcon from "@mui/icons-material/Login";
import "./userActivity.css";
const UserActivities = () => {
  const [userActivity, setUserActivity] = useState([]);
  useEffect(() => {
    getUserActivity();
  }, []);
  const getUserActivity = async () => {
    try {
      const token = localStorage.getItem("token");
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/useractivities`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUserActivity(res.data.activities);
    } catch (error) {}
  };

  return (
    <div className="userActivities">
      {userActivity.map((activities, key) => (
        <div key={key} className="userActivities_date_activity_main">
          <div className="userActivities_date_activity_main_loginicon">
            <LoginIcon
              fontSize="10px"
              className="userActivities_date_activity_main_icon"
              sx={{ color: "#5156be" }}
            />
          </div>
          <div className="userActivities_date_activity">
            <span>{activities.time_created}</span>
            <div className="userActivities_actvity">
              <p>{activities.username}</p>
              <p>{activities.activity}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserActivities;
