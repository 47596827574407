import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import "./style.css";
function EditPasswordByAdmin({
  data: userProp,
  handleEdit,
  setErrorMessage,
  setMessage,
}) {
  const [show, setShow] = useState(false);

  const [user, setUser] = useState({ ...userProp });

  const [password, setPassword] = useState("");
  const editUserPassword = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    const resp = await fetch(
      `${process.env.REACT_APP_BASE_URL}/edit/password/${user._id}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          password: password,
        }),
        method: "PUT",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
        setMessage(res.message);
      })
      .catch((error) => {
        setErrorMessage(resp.error.message);
      });

    // userList();

    setShow(false);
    handleEdit();
  };

  return (
    <div>
      <div>
        <Link to="#" onClick={() => setShow(true)}>
          <button className="editPasswordBtn">
            <VpnKeyOutlinedIcon
              style={{ border: "1px solid", padding: "2px" }}
            />
          </button>
        </Link>
      </div>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title
            style={{
              textTransform: "capitalize",
              letterSpacing: ".2rem",
              fontWeight: 500,
              fontSize: "1rem",
            }}
          >
            change password
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form
            onSubmit={editUserPassword}
            style={{
              padding: "2rem",
              borderRadius: ".375rem",
            }}
          >
            <label>New Password</label>
            <input
              className="form-control mb-4"
              type="text"
              defaultValue=""
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              style={{
                textAlign: "right",
                width: " 100%",
                color: "#ffff",
                fontSize: ".8rem",
                borderRadius: ".375rem",
                backgroundColor: "rgb(81, 86, 190)",
                textTransform: "capitalize",
                letterSpacing: ".2rem",
              }}
              type="submit"
              className=" btn-warning"
              onClick={() => setShow(false)}
              disableRipple
            >
              save
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default EditPasswordByAdmin;
