import "./App.css";
import React from "react";
import Registration from "./compoents/RegistrationSlider/Registration";
import { Routes, Route } from "react-router-dom";
import Home from "./compoents/Home";
import Protected from "./compoents/protectedRoute/Protected";
import Userlist from "./Pages/Userlist";
import Userdetails from "./Pages/Userdetails";
import Addnewuser from "./Pages/Addnewuser";
import PageNotFound from "./PageNotFound/PageNotFound";
import OnlineUsers from "./Pages/OnlineUsers";
import ForgetPassword from "./compoents/ForgetPassword/ForgetPassword";
// import Profile from "./Pages/Profile";
import Prefrences from "./Pages/Prefrencs2";
import { Scrollbars } from "react-custom-scrollbars";
import Footer from "./Pages/Footer";

import UserActivityReport from "./Pages/UserActivityReport";
import ResetPassword from "./Pages/resetPassword/ResetPassword";
import EmailSend from "./Pages/resetPassword/Emailsend";

function App() {
  return (
    <div className="App">
      <Scrollbars style={{ width: "100%", height: "100vh" }}>
        {/* <Registration /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Registration />} />
          <Route element={<Protected />}>
            <Route path="/Userdetails" element={<Userdetails />} />
            <Route path="/Userdetails" element={<Userdetails />} />
            <Route path="/Userlist" element={<Userlist />} />
            <Route path="/Addnewuser" element={<Addnewuser />} />
            <Route path="/OnlineUsers" element={<OnlineUsers />} />
            {/* <Route path="/profile/:id" element={<Profile />} /> */}
            <Route path="/Prefrences" element={<Prefrences />} />
            <Route
              path="/activity-report/:userid"
              element={<UserActivityReport />}
            />
          </Route>
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/emailsend" element={<EmailSend />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>

        <Footer />
      </Scrollbars>
    </div>
  );
}

export default App;
