import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/mxr.svg";
import { Container, LogoBox, InputBox, FormBox } from "../style/login.js";
import { Button } from "@mui/material";
import { useFormik } from "formik";
import "../Login/login.css";
import { registerSchema } from "../schema";
import axios from "axios";
import { useState } from "react";
const initialValues = {
  username: "",
  email: "",
  password: "",
};

function Registration() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    const auth = localStorage.getItem("token");
    document.title = "SignUp";
    if (auth) {
      navigate("/Userdetails");
    }
  }, []);

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      const { username, email, password } = values;
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/register`,
          {
            username: username,
            email: email,
            password: password,
            method: "POST",

            headers: {
              "Content-Type": "Application/json",
            },
          }
        );

        if (response.data.status === "sucess") return navigate("/");
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    },
  });

  return (
    <Container className="login_form">
      <LogoBox className="logo">
        <div className="logo_img">
          <img src={logo} alt="logo_img" />
        </div>
        <div className="logo_text">
          <p>Minia</p>
        </div>
      </LogoBox>
      <div className="header" style={{ marginTop: "123px" }}>
        <h6>Register Account</h6>
        <p>Sign in to continue to Minia.</p>
      </div>
      {errorMessage ? (
        <div className="alert">
          <p className="alert_danger">{errorMessage} </p>
        </div>
      ) : null}
      <FormBox className="form">
        <form onSubmit={handleSubmit}>
          <InputBox className="form_input_login">
            <label htmlFor="email">email</label>
            <input
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="email"
            />
          </InputBox>
          {errors.email && touched.email ? (
            <p className="alert_danger">{errors.email}</p>
          ) : null}
          <InputBox className="form_input_login">
            <label htmlFor="username">username</label>
            <input
              type="text"
              name="username"
              value={values.username}
              onChange={handleChange}
              placeholder="username"
            />
          </InputBox>
          {errors.username && touched.username ? (
            <p className="alert_danger">{errors.username}</p>
          ) : null}
          <InputBox className="form_input_login">
            <label htmlFor="password">password</label>

            <input
              type="password"
              name="password"
              placeholder="password"
              value={values.password}
              onChange={handleChange}
            />
          </InputBox>
          {errors.password && touched.password ? (
            <p className="alert_danger">{errors.password}</p>
          ) : null}

          <span style={{ fontSize: "12px" }}>
            By registering you agree to the Minia Terms of Use
          </span>
          <div className="login">
            <Button
              variant="contained"
              style={{ backgroundColor: "rgb(81, 86, 190)" }}
              size="small"
              type="submit"
            >
              signup
            </Button>
          </div>
        </form>
      </FormBox>

      <div className="signup">
        <p>
          Already have an account ?<Link to="/"> login</Link>
        </p>
      </div>
      <div className="copyright">
        <p>
          &copy; 2022 . Powered By{" "}
          <strong style={{ fontWeight: 500, color: "#495057" }}>
            Meta Extended Reality
          </strong>
        </p>
      </div>
    </Container>
  );
}

export default Registration;
