import React from "react";
// import Sidebar from '../Sidebar/Sidebar'
// import Appbar from "../Appbar/Appbar";

import SideBar from "../Sidebarr/SideBar";

const Dashboard = () => {
  return (
    <>
      <div className="hllo" style={{ display: "flex" }}>
        <SideBar />
      </div>
    </>
  );
};

export default Dashboard;
