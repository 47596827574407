import React from "react";
import "./style/assessmentoverview.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";

const data = [
  {
    name: "jan ",
    uv: 9,
    pv: -9,
  },
  {
    name: "feb ",
    uv: -17,
    pv: 17,
  },
  {
    name: "March",
    uv: -18,
    pv: 18,
  },
  {
    name: "April",
    uv: 8,
    pv: -8,
  },
  {
    name: "May",
    uv: -10,
    pv: 10,
  },
  {
    name: "June",
    uv: 12,
    pv: -12,
  },
  {
    name: "july",
    uv: 17,
    pv: -17,
  },
  {
    name: "Aug",
    uv: 11,
    pv: -11,
  },
  {
    name: "Sep",
    uv: 15,
    pv: -15,
  },
  {
    name: "Oct",
    uv: 20,
    pv: -20,
  },
  {
    name: "Nov",
    uv: -2,
    pv: 2,
  },
  {
    name: "Dec",
    uv: 27,
    pv: -27,
  },
];

function Assessmentoverview() {
  return (
    <div className="assessmentoverview">
      <div className="assessmentoverview_barchart">
        <BarChart
          width={800}
          height={260}
          data={data}
          margin={{
            top: 6,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          className="barchart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="pv" fill="#8884d8" />
          <Bar dataKey="uv" fill="#82ca9d" />
        </BarChart>
      </div>
      <div style={{ display: "flex" }} className="assessmentoverview_content">
        <ul style={{ listStyle: "number" }}>
          <li>Paul Andres </li>
          <br />
          <li>Mark Edword</li>
          <br />

          <li>Matt Edisson</li>
        </ul>
        <ul style={{ listStyle: "none" }}>
          <li>
            <p
              style={{
                backgroundColor: "rgb(130, 202, 157)",
                borderRadius: "2.5rem",
                fontSize: "small",
              }}
            >
              +2.5%
            </p>
          </li>
          <li>
            <p
              style={{
                backgroundColor: "rgb(130, 202, 157)",
                borderRadius: "2.5rem",
                fontSize: "small",
                marginTop: "32px",
              }}
            >
              +8.3%
            </p>
          </li>
          <div>
            <li>
              <p
                style={{
                  backgroundColor: "rgb(225 179 190)",
                  borderRadius: "2.5rem",
                  fontSize: "small",
                  marginTop: "32px",
                }}
              >
                -3.6%
              </p>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
}
export default Assessmentoverview;
