import axios from "axios";

export const userList = async () => {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/allusers/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const onlineUserList = async () => {
  const token = localStorage.getItem("token");
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/online-users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const onlineUserCount = async () => {
  const token = localStorage.getItem("token");
  return await axios.get(`${process.env.REACT_APP_BASE_URL}/onlineuserscount`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
