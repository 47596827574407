import { Box, styled } from "@mui/material";
import back2 from "../../images/bacimg.jpg";

export const SliderContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${back2})`,

  width: "100%",

  backgroundRepeat: "no-repeat",
  opacity: ".6",
  backgroundSize: "cover",
  filter: "grayscale(80%)",
}));

export const StackBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100vh",
  // position: "center",
  bottom: 0,
  "*": {
    // width: "20px",
  },
  [theme.breakpoints.down(1050)]: {
    display: "none",
  },
}));
