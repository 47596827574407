import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import "./PageNotFound.css";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div class="text-div">
      <h1 class="number">404</h1>
      <Button
        onClick={() => navigate(-1)}
        variant="contained"
        style={{ marginLeft: "944px", marginTop: "173px", background: "black" }}
      >
        Go Back
      </Button>
    </div>
  );
};

export default PageNotFound;
