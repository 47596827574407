import React from "react";
import { SliderContainer, StackBox } from "../style/slider";
// import bubble from "../../images/bubbl2.jpg";

import "../Slider/sider.css";

const Slider = () => {
  return (
    <SliderContainer>
      <StackBox className="bubbles"></StackBox>
    </SliderContainer>
  );
};

export default Slider;
