import { styled, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import "../../compoents/ForgetPassword/forget.css";
import { useFormik } from "formik";
import { resetPassword } from "../../compoents/schema";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LOGO from "../../images/mxr.svg";
import LockResetIcon from "@mui/icons-material/LockReset";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    width: "100%",
    gap: "30px",
  },
}));
const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > :nth-of-type(1)": {
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
}));
const StyledMain = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  padding: "20px",
}));

const initialValues = {
  password: "",
};
const ResetPassword = () => {
  const [message, setMessage] = useState("");
  const [errMessage, setErrorMessage] = useState("");
  useEffect(() => {
    document.title = "Reset Password";
  });
  const navigate = useNavigate();
  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues,
    validationSchema: resetPassword,
    onSubmit: async (values) => {
      const { password } = values;
      try {
        let response = await axios.patch(
          `${process.env.REACT_APP_BASE_URL}/resetpassword/${token}`,
          {
            password,

            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setErrorMessage(null);
        setMessage(response.data.message);

        setTimeout(() => {
          navigate("/");
        }, 10000);
      } catch (error) {
        setMessage(null);
        setErrorMessage(error.response.data.messsage);
        setTimeout(() => {
          navigate("/");
        }, 10000);
      }
    },
  });

  const { token } = useParams();
  return (
    <div>
      <StyledBox
        className="form"
        style={{ textTransform: "capitalize", fontWeight: "500" }}
      >
        <StyledMain style={{ borderRadius: "8px" }}>
          {message ? <span className="alert_green">{message}</span> : null}
          {errMessage ? (
            <span className="alert_danger">{errMessage}</span>
          ) : null}
          <div className="log_img">
            <img src={LOGO} alt="logo_img" />
          </div>
          <LockResetIcon fontSize="large" />
          <div className="forget_heading">
            <span>reset password?</span>
          </div>
          <div className="forget_sub-heading">
            <p>no worries reset your password here</p>
          </div>

          <StyledForm className="formforget">
            <Form onSubmit={handleSubmit}>
              <FormGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                className="mb-30"
              >
                {errors.password && touched.password ? (
                  <p
                    className="error"
                    style={{
                      color: "red",
                      fontSize: ".9rem",
                      backgroundColor: "#dff5ee",
                      padding: "5px",
                      width: "100%",
                      textAlign: "center",
                      textTransform: "lowercase",
                      fontWeight: 400,
                    }}
                  >
                    {errors.password}
                  </p>
                ) : null}
                <Label for="email">new password</Label>
                <input
                  type="password"
                  name="password"
                  id="psw"
                  placeholder="new password"
                  value={values.password}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button
                type="submit"
                variant="contained"
                className="form-button"
                disableElevation
              >
                save
              </Button>
            </Form>
          </StyledForm>

          <Button
            onClick={() => navigate("/")}
            startIcon={<ArrowBackIcon fontSize="small" />}
            disableElevation
          >
            back to login
          </Button>
        </StyledMain>
      </StyledBox>
    </div>
  );
};

export default ResetPassword;
