import React, { useEffect, useState } from "react";
// import "./userdetails.css";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import Userstatus from "../compoents/Graph/Userstatus";
import Coursestats from "../compoents/Graph/Coursestats";
import Scenarioprogress from "../compoents/Graph/Scenarioprogress";
import Assessmentoverview from "../compoents/Graph/Assessmentoverview";
import Dashboard from "../compoents/DashBoard/Dashboard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Appbar from "../compoents/Appbar/Appbar";

import { Row, Col } from "react-bootstrap";
import "./styles/userDetails.css";
import UserActivities from "./userActivities/UserActivities";
import MapChart from "../compoents/Graph/Map/MapChart";
const Userdetails = () => {
  useEffect(() => {
    document.title = "Dashboard";
    AllUsersCount();
    onlineUserDataList();
    usersCount("users");
    // managerss("managers");
    // adminss("admins");
    roles();
  }, []);
  const [users, setUsers] = useState("");
  const [rolesCount, setRolesCount] = useState([]);
  // const [admins, setAdmins] = useState("");
  // const [managers, setManagers] = useState("");
  const [allusersCount, setAllusersCount] = useState("");
  const [data, setData] = useState([]);
  const onlineUserDataList = async () => {
    try {
      let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/city`);
      setData(res.data.city);
    } catch (error) {}
  };
  console.log(data);
  const usersCount = async (role) => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/count/${role}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUsers(res.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const roles = async () => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.get(`${process.env.REACT_APP_BASE_URL}/roles`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("roles:" + res.data.roles);
      setRolesCount(res.data.roles);
    } catch (error) {}
  };

  const AllUsersCount = async () => {
    try {
      const token = localStorage.getItem("token");
      let res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/count/allusers`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllusersCount(res.data.allUsers);
    } catch (error) {}
  };
  const role = localStorage.getItem("role");

  return (
    <div className="userdetails_main">
      <div style={{ backgroundColor: "#5156be" }}>
        <Dashboard />
      </div>

      <div style={{ width: "100%" }}>
        <Appbar />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <div
            className="user"
            style={{ marginTop: "20px", fontWeight: "500", fontSize: "1rem" }}
          >
            <p>Dashboard</p>
          </div>

          <div className="userdetails_admin">
            <p style={{ textTransform: "capitalize" }}>
              {role} <KeyboardArrowRightIcon />
            </p>

            <p style={{ textTransform: "capitalize" }}>Dashboard</p>
          </div>
        </div>

        <div className="main_card">
          <Card
            sx={{
              height: "120px",
              border: "1px solid #ebdede",
              flex: "1",
            }}
          >
            <CardContent className="userdetails_role_cards_content">
              <Typography
                gutterBottom
                variant="p"
                component="div"
                className="userdetails_role_cards_content_text"
              >
                Total Users
                <span style={{ fontSize: "1.3rem", fontWeight: 600 }}>
                  {allusersCount}
                </span>
              </Typography>

              <GroupsIcon className="userdetails_role_cards_content_groupicon" />
            </CardContent>
          </Card>
          {rolesCount.map((role) => (
            <Card
              sx={{
                height: "120px",
                flex: "1",
              }}
            >
              <CardContent className="userdetails_role_cards_content">
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  className="userdetails_role_cards_content_text"
                >
                  Number of {role._id}
                  <span style={{ fontSize: "1.3rem", fontWeight: 600 }}>
                    {role.roles}
                  </span>
                </Typography>

                <GroupsIcon className="userdetails_role_cards_content_groupicon" />
              </CardContent>
            </Card>
          ))}
        </div>
        {/* second card section */}
        <div className="userdetails_second_main_card">
          <Card
            sx={{
              height: "300px",
              flex: "1",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="p"
                component="p"
                className="userdetails_second_main_card_text"
              >
                User stats
              </Typography>

              <Userstatus users={users} />
            </CardContent>
          </Card>

          <Card
            sx={{
              height: "300px",
              flex: "1",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="p"
                component="p"
                className="userdetails_second_main_card_text"
              >
                Course stats
              </Typography>
              <Coursestats />
            </CardContent>
          </Card>

          <Card
            sx={{
              height: "300px",

              flex: "1",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="p"
                component="p"
                className="userdetails_second_main_card_text"
              >
                Scenario Progress
              </Typography>

              <Scenarioprogress />
            </CardContent>
          </Card>
        </div>
        {/* third card section */}
        <div
          style={{
            marginLeft: "20px",
            justifyContent: "center",
            marginRight: "20px",
          }}
        >
          <Card
            sx={{
              marginTop: "50px",
            }}
          >
            <CardContent>
              <Typography
                gutterBottom
                variant="p"
                component="div"
                sx={{
                  color: "black",
                  fontWeight: "400",
                  fontSize: ".9rem",
                }}
              >
                Assessment Overview
              </Typography>

              <Assessmentoverview />
            </CardContent>
          </Card>
        </div>
        {/* fourth card section Map */}
        <div className="sales_by_locations_recent_activiteis">
          <div style={{ flex: 1 }}>
            <Typography
              gutterBottom
              variant="p"
              component="p"
              className="sales_by_locations_p"
            >
              Sales by Locations
            </Typography>
            <Card
              sx={{
                height: "300px",
                borderRadius: "0px 0px 4px 4px",
                flexGrow: 1,
              }}
            >
              <CardContent
                style={{
                  padding: 0,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="online_users_details">
                  <p>location</p>
                  {data.length > 0 ? (
                    <>
                      {data.map((user, index) => (
                        <Row key={index} className="online_users_city">
                          <Col
                            style={{ listStyle: "none" }}
                            className="online_users_city_first"
                          >
                            {user._id}
                          </Col>
                          <Col
                            style={{ listStyle: "none" }}
                            className="online_users_city_last"
                          >
                            {user.count}
                          </Col>
                        </Row>
                      ))}
                    </>
                  ) : (
                    "0"
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <MapChart />
                </div>
              </CardContent>
            </Card>
          </div>
          <div
            style={{ flex: 1, marginBottom: "2.4rem" }}
            className="recent_activiteis_main"
          >
            <div className="recent_activiteis">
              <Typography
                gutterBottom
                variant="p"
                component="p"
                className="recent_activiteis_p"
              >
                Recent Activity
              </Typography>
              <select className="recent_activiteis_options">
                <option value="2">today</option>
                <option value="7">yesterday</option>
                <option value="8">last week</option>
                <option value="8">last Month</option>
              </select>
            </div>
            <Card
              sx={{
                height: "300px",

                flexGrow: 1,
                borderRadius: "0px 0px 4px 4px",
                overflowY: "scroll",
              }}
            >
              <CardContent style={{ padding: 0 }}>
                <UserActivities />
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userdetails;
