import { styled, Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import "../../compoents/ForgetPassword/forget.css";
import { useFormik } from "formik";
import { resetPassword } from "../../compoents/schema";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LOGO from "../../images/mxr.svg";
import LockResetIcon from "@mui/icons-material/LockReset";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  [theme.breakpoints.up("md")]: {
    width: "100%",
    gap: "30px",
  },
}));
const StyledForm = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "& > :nth-of-type(1)": {
    width: "400px",
    [theme.breakpoints.down("md")]: {
      width: "300px",
    },
  },
}));
const StyledMain = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  padding: "20px",
}));

const EmailSend = () => {
  useEffect(() => {
    document.title = "Reset Password";
  });
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <StyledBox
        className="form"
        style={{ textTransform: "capitalize", fontWeight: "500" }}
      >
        <StyledMain style={{ borderRadius: "8px" }}>
          <div className="log_img">
            <img src={LOGO} alt="logo_img" />
          </div>
          <div className="emailsend">
            <h3>FORGOT YOUR PASSWORD?</h3>
            <h6>
              We have sent you an email with a link to reset your password. If
              you have not received the email within 15 minutes, please check
              your junk/spam folder .
            </h6>
          </div>
          <Button
            onClick={() => navigate("/")}
            startIcon={<ArrowBackIcon fontSize="small" />}
            disableElevation
          >
            back to login
          </Button>
        </StyledMain>
      </StyledBox>
    </div>
  );
};

export default EmailSend;
