import React from "react";
import "./styles/footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_left">
        <p>2022 &copy; mxr</p>
      </div>
      <div className="footer_right">
        <p>
          Design & Develop by{" "}
          <a href="https://www.mxr.ai">Mata Extended Reality</a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
