import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { useEffect } from "react";
const Coursestats = () => {
  const [course, setCourse] = useState([]);
  useEffect(() => {
    getCourseDetails();
  }, []);
  const getCourseDetails = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/coursestatus`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourse(res.data);
    } catch (error) {}
  };

  const series = [
    course.courseEnrolled,
    course.courseCompleted,
    course.courseNotstarted,
  ];
  const options = {
    chart: {
      width: "80%",
      type: "pie",
    },

    labels: [
      `Course Enrollments (${course.courseEnrolled})`,
      `Course Completions (${course.courseCompleted})`,
      `Not started yet (${course.courseNotstarted})`,
    ],
    colors: ["#4C9E85", "#407363", "#32C9C4"],

    theme: {
      palette: "palette2",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={"85%"}
      />
    </div>
  );
};

export default Coursestats;
