import { styled, Box } from "@mui/material";
import React from "react";
import Registration from "../Registration/Registration";
import { Scrollbars } from "react-custom-scrollbars";
import Slider from "../Slider/Slider";
const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [theme.breakpoints.down(1050)]: {
    flexDirection: "column",
    justifyContent: "center",
  },
}));
const Home = () => {
  return (
    <>
      <Scrollbars style={{ width: "100%", height: "100vh" }}>
        <StyledBox>
          <Registration />
          <Slider />
        </StyledBox>
      </Scrollbars>
    </>
  );
};

export default Home;
