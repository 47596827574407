import React, { useEffect } from "react";

import Card from "@mui/material/Card";

import Appbar from "../compoents/Appbar/Appbar";

import Dashboard from "../compoents/DashBoard/Dashboard";

import CardContent from "@mui/material/CardContent";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";

import RadioGroup from "@mui/material/RadioGroup";

import { useState } from "react";
import axios from "axios";
import io from "socket.io-client";
import "./styles/prefrences.css";

const socket = io.connect(process.env.REACT_APP_URL_SOCKETIO);

function Prefrences() {
  useEffect(() => {
    document.title = "Prefrences";
    getSettings();
  }, []);
  const [playingMode, setPlayMode] = useState("");
  const [lightingMode, setLightingMode] = useState("");
  const [gen, setGen] = useState({});
  const [waterTank, setWaterTank] = useState({});
  const [marker1, setMarker1] = useState({});
  const [marker2, setMarker2] = useState({});
  const [marker3, setMarker3] = useState({});
  const [marker4, setMarker4] = useState({});
  const [marker5, setMarker5] = useState({});
  const [marker6, setMarker6] = useState({});
  const [tag1, setTag1] = useState({});
  const [tag2, setTag2] = useState({});
  const [tag3, setTag3] = useState({});
  const [tag4, setTag4] = useState({});
  const [tag5, setTag5] = useState({});
  const [tag6, setTag6] = useState({});
  const [fireExt1, setFireExt1] = useState({});
  const [fireExt2, setFireExt2] = useState({});
  const [fireExt3, setFireExt3] = useState({});
  const [fireExt4, setFireExt4] = useState({});
  const [fireExt5, setFireExt5] = useState({});
  const [fireExt6, setFireExt6] = useState({});
  const [id, setId] = useState();

  const handleRadioLightingMode = (e) => {
    setLightingMode(e.target.value);
    changeModeCntrl(e.target.value);
    updateLightingMode();
  };

  const handleRadioPlayingMode = (e) => {
    const { name, value } = e.target;
    setPlayMode((playingMode) => {
      return { ...playingMode, [name]: value };
    });
    // changeModeCntrl(e.target.value);
  };
  const handleRadioDamage = (e) => {
    const { name, value } = e.target;
    setGen((gen) => {
      return { ...gen, [name]: value };
    });

    // updateDamageGen();
    changeDamageCntrl({ objectname: "generator", status: e.target.value });
  };

  const handleRadioDamage1 = (e) => {
    const { name, value } = e.target;
    setWaterTank((waterTank) => {
      return { ...waterTank, [name]: value };
    });
    changeDamageCntrl({ objectname: "water_tank", status: e.target.value });
    // updateDamagewaterTank();
  };
  const handleRadioMarker1 = (e) => {
    const { name, value } = e.target;
    setMarker1((marker1) => {
      return { ...marker1, [name]: value };
    });
    changeDamageCntrl({ objectname: "Marker_1", status: e.target.value });
    updateMarker1();
  };
  const handleRadiomarker2 = (e) => {
    const { name, value } = e.target;
    setMarker2((marker2) => {
      return { ...marker2, [name]: value };
    });
    changeDamageCntrl({ objectname: "Marker_2", status: e.target.value });
  };
  const handleRadiomarker3 = (e) => {
    const { name, value } = e.target;
    setMarker3((marker3) => {
      return { ...marker3, [name]: value };
    });
    changeDamageCntrl({ objectname: "Marker_3", status: e.target.value });
  };
  const handleRadiomarker4 = (e) => {
    const { name, value } = e.target;
    setMarker4((marker4) => {
      return { ...marker4, [name]: value };
    });
    changeDamageCntrl({ objectname: "Marker_4", status: e.target.value });
  };
  const handleRadiomarker5 = (e) => {
    const { name, value } = e.target;
    setMarker5((marker5) => {
      return { ...marker5, [name]: value };
    });

    changeDamageCntrl({ objectname: "Marker_5", status: e.target.value });
  };
  const handleRadiomarker6 = (e) => {
    const { name, value } = e.target;
    setMarker6((marker6) => {
      return { ...marker6, [name]: value };
    });

    changeDamageCntrl({ objectname: "Marker_6", status: e.target.value });
  };
  const handleRadioTag1 = (e) => {
    const { name, value } = e.target;
    setTag1((tag1) => {
      return { ...tag1, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_1", status: e.target.value });
  };
  const handleRadioTag2 = (e) => {
    const { name, value } = e.target;
    setTag2((tag2) => {
      return { ...tag2, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_2", status: e.target.value });
  };
  const handleRadioTag3 = (e) => {
    const { name, value } = e.target;
    setTag3((tag3) => {
      return { ...tag3, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_3", status: e.target.value });
  };
  const handleRadioTag4 = (e) => {
    const { name, value } = e.target;
    setTag4((tag4) => {
      return { ...tag4, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_4", status: e.target.value });
  };
  const handleRadioTag5 = (e) => {
    const { name, value } = e.target;
    setTag5((tag5) => {
      return { ...tag5, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_5", status: e.target.value });
  };
  const handleRadioTag6 = (e) => {
    const { name, value } = e.target;
    setTag5((tag6) => {
      return { ...tag6, [name]: value };
    });
    changeDamageCntrl({ objectname: "Tag_6", status: e.target.value });
  };
  const handleRadioFireExt1 = (e) => {
    const { name, value } = e.target;
    setFireExt1((fireExt1) => {
      return { ...fireExt1, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_1", status: e.target.value });
  };
  const handleRadioFireExt2 = (e) => {
    const { name, value } = e.target;
    setFireExt2((fireExt2) => {
      return { ...fireExt2, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_2", status: e.target.value });
  };
  const handleRadioFireExt3 = (e) => {
    const { name, value } = e.target;
    setFireExt3((fireExt3) => {
      return { ...fireExt3, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_3", status: e.target.value });
  };
  const handleRadioFireExt4 = (e) => {
    const { name, value } = e.target;
    setFireExt4((fireExt4) => {
      return { ...fireExt4, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_4", status: e.target.value });
  };
  const handleRadioFireExt5 = (e) => {
    const { name, value } = e.target;
    setFireExt5((fireExt5) => {
      return { ...fireExt5, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_5", status: e.target.value });
    updateFireExt5();
  };
  const handleRadioFireExt6 = (e) => {
    const { name, value } = e.target;
    setFireExt5((fireExt6) => {
      return { ...fireExt6, [name]: value };
    });
    changeDamageCntrl({ objectname: "Fire_ext_6", status: e.target.value });
    updateFireExt5();
  };

  const changeDamageCntrl = (arg) => {
    socket.emit("changeDamageCntrl", arg);
  };

  const changeModeCntrl = (lm) => {
    socket.emit("changeMode", lm);
  };
  const getSettings = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getsettings`
      );
      setId(res.data.data[0]._id);
      setLightingMode(res.data.data[0].ligting_mode);
      setPlayMode(res.data.data[0].play_mode);
      setGen(res.data.data[0].damage_control[0]);
      setWaterTank(res.data.data[0].damage_control[1]);
      setMarker1(res.data.data[0].damage_control[2]);
      setTag1(res.data.data[0].damage_control[3]);
      setFireExt1(res.data.data[0].damage_control[4]);
      setMarker2(res.data.data[0].damage_control[5]);
      setTag2(res.data.data[0].damage_control[6]);
      setFireExt2(res.data.data[0].damage_control[7]);
      setMarker3(res.data.data[0].damage_control[8]);
      setTag3(res.data.data[0].damage_control[9]);
      setFireExt3(res.data.data[0].damage_control[10]);
      setMarker4(res.data.data[0].damage_control[11]);
      setTag4(res.data.data[0].damage_control[12]);
      setFireExt4(res.data.data[0].damage_control[13]);
      setMarker5(res.data.data[0].damage_control[14]);
      setTag5(res.data.data[0].damage_control[15]);
      setFireExt5(res.data.data[0].damage_control[16]);
      setMarker6(res.data.data[0].damage_control[17]);
      setTag6(res.data.data[0].damage_control[18]);
      setFireExt6(res.data.data[0].damage_control[19]);
    } catch (error) {}
  };
  const updateDamageGen = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "generator",
        status: gen.status,
      });
    } catch (error) {}
  };
  const updateDamagewaterTank = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "watertank",
        status: waterTank.status,
      });
    } catch (error) {}
  };
  const updateMarker1 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_1",
        status: marker1.status,
      });
    } catch (error) {}
  };
  const updateTag1 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_1",
        status: tag1.status,
      });
    } catch (error) {}
  };
  const updateTag3 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_3",
        status: tag3.status,
      });
    } catch (error) {}
  };
  const updateTag4 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_4",
        status: tag4.status,
      });
    } catch (error) {}
  };
  const updateTag5 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_5",
        status: tag5.status,
      });
    } catch (error) {}
  };
  const updateTag6 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_6",
        status: tag6.status,
      });
    } catch (error) {}
  };
  const updateFireExt1 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_1",
        status: fireExt1.status,
      });
    } catch (error) {}
  };
  const updateFireExt2 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_2",
        status: fireExt2.status,
      });
    } catch (error) {}
  };
  const updateFireExt3 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_3",
        status: fireExt3.status,
      });
    } catch (error) {}
  };
  const updateFireExt4 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_4",
        status: fireExt4.status,
      });
    } catch (error) {}
  };
  const updateFireExt5 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_5",
        status: fireExt5.status,
      });
    } catch (error) {}
  };
  const updateFireExt6 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "fire_ext_6",
        status: fireExt6.status,
      });
    } catch (error) {}
  };
  const updateMarker2 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_2",
        status: marker2.status,
      });
    } catch (error) {}
  };
  const updateMarker3 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_3",
        status: marker3.status,
      });
    } catch (error) {}
  };
  const updateMarker4 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_4",
        status: marker4.status,
      });
    } catch (error) {}
  };
  const updateMarker5 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_5",
        status: marker5.status,
      });
    } catch (error) {}
  };
  const updateMarker6 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "marker_6",
        status: marker5.status,
      });
    } catch (error) {}
  };
  const updateTag2 = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/settings`, {
        objectname: "tag_2",
        status: tag2.status,
      });
    } catch (error) {}
  };
  const updatePlayingMode = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/settings/updateplaying/${id}`,

        playingMode
      );
    } catch (error) {}
  };
  const updateLightingMode = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/settings/updatelighting/${id}`,
        lightingMode
      );
    } catch (error) {}
  };

  const updatePrefrence = () => {
    updateDamageGen();
    updateDamagewaterTank();
    updateMarker1();
    updateMarker2();
    updateMarker3();
    updateMarker4();
    updateMarker5();
    updateMarker6();
    updateTag1();
    updateTag2();
    updateTag3();
    updateTag4();
    updateTag5();
    updateTag6();
    updateFireExt1();
    updateFireExt2();
    updateFireExt3();
    updateFireExt4();
    updateFireExt5();
    updateFireExt6();
    updatePlayingMode();
    updateLightingMode();
  };
  const admin = localStorage.getItem("role");
  return (
    <div style={{ display: "flex", backgroundColor: "#fff" }}>
      <div style={{ backgroundColor: "#5156be" }}>
        <Dashboard />
      </div>
      <div style={{ width: "100%" }}>
        <Appbar />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginRight: "20px",
          }}
        >
          <div
            className="user"
            style={{
              marginTop: "20px",
              display: "flex",
              marginLeft: "20px",
              fontWeight: "600",
            }}
          >
            <p>Prefrences</p>
          </div>

          <div
            className="admin"
            style={{
              display: "flex",
              alignItems: "center",
              paddingRight: "10px",
              fontSize: ".8rem",
            }}
          >
            <p style={{ textTransform: "capitalize" }}>
              {admin} <KeyboardArrowRightIcon />
            </p>
            <p>Prefrences</p>
          </div>
        </div>

        {/* Scenario Prefrences first CardContent Section  */}

        <div
          style={{
            margin: "20px",
          }}
        >
          <div>
            <Card>
              <CardContent sx={{ height: 50 }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontSize: "1rem", fontWeight: "500" }}
                >
                  Scenario Prefrences
                </Typography>
              </CardContent>

              <div style={{ borderTop: "1px solid #e7d8d8" }}>
                <CardContent>
                  <div className="prefreence_main_radio_card">
                    <div className="prefrence_label">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Ligting Mode
                      </label>
                    </div>
                    <div className="prefrence_radio">
                      <RadioGroup
                        key={`${lightingMode}`}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="radio"
                        onChange={handleRadioLightingMode}
                        defaultValue={lightingMode}
                      >
                        <FormControlLabel
                          value="day"
                          name="lightingMode"
                          control={<Radio size="small" />}
                          label="Day"
                        />

                        <FormControlLabel
                          value="night"
                          name="lightingMode"
                          control={<Radio size="small" />}
                          label="Night"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div className="prefreence_main_radio_card">
                    <div className="prefrence_label">
                      <label className="Prefrences_radio_labels">
                        Play Mode
                      </label>
                    </div>
                    <div className="prefrence_radio">
                      <RadioGroup
                        key={`${playingMode}`}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        className="radio"
                        onChange={handleRadioPlayingMode}
                        defaultValue={playingMode}
                      >
                        <FormControlLabel
                          value="single"
                          name="playingMode"
                          control={<Radio size="small" />}
                          label="Single Player"
                        />

                        <FormControlLabel
                          value="multi"
                          name="playingMode"
                          control={<Radio size="small" />}
                          label="Multi Player"
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </CardContent>
              </div>
            </Card>
          </div>
        </div>

        {/*    Items Control/Show/Hide items in Scenario second CardContent Section  */}
        <div>
          <div className="prefrence_cards">
            <Card
              sx={{
                flex: "1",
              }}
            >
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  style={{ fontSize: "1rem", fontWeight: "500" }}
                >
                  Items Control
                  <p style={{ fontSize: ".8rem", fontWeight: "400" }}>
                    Show/Hide items in Scenario
                  </p>
                </Typography>
              </CardContent>

              <div style={{ borderTop: "1px solid #e7d8d8" }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 1
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker1.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioMarker1}
                          defaultValue={marker1.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag1.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag1}
                          defaultValue={tag1.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt1.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt1}
                          defaultValue={fireExt1.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 2
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker2.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadiomarker2}
                          defaultValue={marker2.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag2.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag2}
                          defaultValue={tag2.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt2.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt2}
                          defaultValue={fireExt2.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 3
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker3.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadiomarker3}
                          defaultValue={marker3.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag3.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag3}
                          defaultValue={tag3.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt3.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt3}
                          defaultValue={fireExt3.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon
                        style={{ fontSize: "1rem", fontWeight: "500" }}
                      />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 4
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker4.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadiomarker4}
                          defaultValue={marker4.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag4.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag4}
                          defaultValue={tag4.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt4.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt4}
                          defaultValue={fireExt4.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 5
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker5.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadiomarker5}
                          defaultValue={marker5.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag5.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag5}
                          defaultValue={tag5.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt5.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt5}
                          defaultValue={fireExt5.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        color: "rgb(81, 86, 190)",
                        fontSize: "15px",
                        fontWeight: "900",
                      }}
                    >
                      <ArrowForwardIcon style={{ fontSize: "18px" }} />
                      <p style={{ fontSize: "1rem", fontWeight: "500" }}>
                        Fire Point 6
                      </p>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Marker
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${marker6.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadiomarker6}
                          defaultValue={marker6.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>

                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        First Aid Tag
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${tag6.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioTag6}
                          defaultValue={tag6.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Show"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Hide"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="markers">
                      <label
                        id="demo-row-radio-buttons-group-label"
                        className="Prefrences_radio_labels"
                      >
                        Fire Extinguisher
                      </label>
                      <div className="prefrence_radio">
                        <RadioGroup
                          key={`${fireExt6.status}`}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="radio"
                          onChange={handleRadioFireExt6}
                          defaultValue={fireExt6.status}
                        >
                          <FormControlLabel
                            value="1"
                            name="status"
                            control={<Radio size="small" />}
                            label="Valid"
                          />

                          <FormControlLabel
                            value="0"
                            name="status"
                            control={<Radio size="small" />}
                            label="Expired"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </Typography>
                </CardContent>
              </div>
            </Card>

            {/*     Damage Control/ Enable/disable Damage of Particular asset in game third CardContent Section  */}
            <div style={{ flex: "1" }}>
              <Card sx={{}}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ fontSize: "1rem", fontWeight: "500" }}
                  >
                    Damage Control
                    <p style={{ fontSize: ".8rem", fontWeight: "400" }}>
                      Enable/disable Damage of Particular asset in game
                    </p>
                  </Typography>
                </CardContent>

                <div style={{ borderTop: "1px solid #e7d8d8" }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      <div className="markers">
                        <label
                          id="demo-row-radio-buttons-group-label"
                          className="Prefrences_radio_labels"
                        >
                          Generator
                        </label>
                        <div className="prefrence_radio">
                          <RadioGroup
                            key={`${gen.status}`}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="radio"
                            onChange={handleRadioDamage}
                            defaultValue={gen.status}
                          >
                            <FormControlLabel
                              value="1"
                              name="status"
                              control={<Radio size="small" />}
                              label="Enable"
                            />

                            <FormControlLabel
                              value="0"
                              name="status"
                              control={<Radio size="small" />}
                              label="Disable"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                      <div className="markers">
                        <label
                          id="demo-row-radio-buttons-group-label"
                          className="Prefrences_radio_labels"
                        >
                          Water Tank
                        </label>
                        <div className="prefrence_radio">
                          <RadioGroup
                            key={`${waterTank.status}`}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="radio"
                            onChange={handleRadioDamage1}
                            defaultValue={waterTank.status}
                          >
                            <FormControlLabel
                              value="1"
                              name="status"
                              control={<Radio size="small" />}
                              label="Enable"
                            />

                            <FormControlLabel
                              value="0"
                              name="status"
                              control={<Radio size="small" />}
                              label="Disable"
                            />
                          </RadioGroup>
                        </div>
                      </div>
                    </Typography>
                  </CardContent>
                </div>
              </Card>
              <div className="pref_btn">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#5156be",
                    marginTop: "1rem",
                    boxShadow: "0 2px 6px 0 rgb(81 86 190 / 50%",

                    color: "#fff",
                    fontSize: ".6rem",
                  }}
                  onClick={updatePrefrence}
                >
                  save changes
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
export default Prefrences;
