import * as YUP from "yup";

export const loginSchema = YUP.object({
  // email: YUP.string().email().required(),
  username: YUP.string().required("username is required"),
  password: YUP.string().min(8).required("passsworis required"),
});

export const registerSchema = YUP.object({
  username: YUP.string().min(2).max(25).required("username is required"),
  email: YUP.string().email("please provide a valid email").required(),
  password: YUP.string().min(8).required(),
});
export const forgetSchema = YUP.object({
  email: YUP.string().email("please provide a valid email").required(),
});
export const passwordForgetSchema = YUP.object({
  currentPassword: YUP.string().min(8).required(),
  newPassword: YUP.string().min(8).required(),
  confirmPassword: YUP.string().min(8).required(),
});

// export const AddnewuserSchema = YUP.object({

//   username: YUP.string().min(2).max(25).required("username is required"),
//   email: YUP.string().email().required("Email is required"),
//   password: YUP.string().required("Password is required").min(6, "Password is too short - should be 6 chars minimum"),

// });

export const resetPassword = YUP.object({
  password: YUP.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 8 chars minimum"),
});
