import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";

import Dashboard from "../compoents/DashBoard/Dashboard";
import Appbar from "../compoents/Appbar/Appbar";
import img from "../compoents/assets/images/admin.jpeg";
import { onlineUserCount, onlineUserList } from "../services/api";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import axios from "axios";
import "./onlineuser.css";

function OnlineUsers() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState("");
  const [usr, setUsr] = useState({});
  const [model, setModel] = useState(false);
  const [model1, setModel1] = useState(false);
  const [model2, setModel2] = useState(false);
  const [err, seterr] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [fire_ext, setfire_ext] = useState([]);
  const [markers, setmarkers] = useState([]);
  const [tags, settags] = useState([]);

  useEffect(() => {
    document.title = "Online Users";
    onlineUserDataList();
  }, []);

  const onlineUserDataList = async () => {
    let res = await onlineUserList();
    let resp = await onlineUserCount();
    setCount(resp.data);
    setData(res.data);
  };
  const handleClick = async (id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/onlineuser/${id}`
      );
      setUsr(res.data.user);
    } catch (error) {}
  };

  const viewReport = async (userid) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/recordactivity/${userid}`
      );

      setItemList(res.data.record.itemlist);
      setfire_ext(res.data.record.fire_ext);
      setmarkers(res.data.record.markers);
      settags(res.data.record.tags);
      seterr(false);
    } catch (error) {
      seterr(true);
      setItemList(null);
      setfire_ext(null);
      setmarkers(null);
      settags(null);
    }
  };

  return (
    <>
      <div className="onlineUsers_main">
        <div style={{ backgroundColor: "#5156be" }}>
          <Dashboard />
        </div>
        <div style={{ width: "100%" }}>
          <Appbar />
          <div className="onlineUsers_main_count">
            <div className="user">
              <p>User List</p>
              <p>&nbsp; ({count.count})</p>
            </div>
          </div>

          <div className="onlineUsers_card">
            {data.map((user, index) => (
              <Card sx={{ backgroundColor: "#f8f9fa" }} key={index}>
                <>
                  <div className="onlineUsers_card_content">
                    <Avatar
                      alt="Travis Howard"
                      src={img}
                      sx={{
                        width: 70,
                        height: 70,
                      }}
                    />

                    <span>{user.name}</span>
                    <p
                      style={{
                        fontSize: "small",
                        textTransform: "capitalize",
                      }}
                    >
                      Site {user.role}
                    </p>
                  </div>

                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        setModel(true);
                        handleClick(user._id);
                      }}
                      className="online_btn"
                    >
                      More Info
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setModel1(true);
                        viewReport(user.userId);
                      }}
                      className="online_btn"
                    >
                      View Report
                    </Button>
                    <Button
                      size="small"
                      onClick={() => {
                        setModel2(true);
                      }}
                      className="online_btn"
                    >
                      Recorded Video
                    </Button>
                  </CardActions>
                </>
              </Card>
            ))}
          </div>
        </div>
        <Modal
          isOpen={model}
          toggle={() => setModel(!model)}
          style={{ padding: "20px" }}
        >
          <ModalHeader toggle={() => setModel(!model)}>
            user information
          </ModalHeader>
          <ModalBody>
            <div className="userInfo">
              <div className="userinfotxt">
                Name: <p>{usr.name}</p>
              </div>
              <div className="userinfotxt">
                username: <p>{usr.username}</p>
              </div>
              <div className="userinfotxt">
                Email: <p>{usr.email}</p>
              </div>
              <div className="userinfotxt">
                ip address: <p>{usr.ipaddress}</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={model2}
          toggle={() => setModel2(!model2)}
          style={{ padding: "20px" }}
        >
          <ModalHeader toggle={() => setModel2(!model2)}>
            user information
          </ModalHeader>
          <ModalBody>
            <div className="userInfo">
              <video style={{ height: 300, width: "100%" }} controls>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                  type="video/mp4"
                ></source>
                Sorry, your browser doesn't support videos.
              </video>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={model1}
          toggle={() => setModel1(!model1)}
          style={{ padding: "20px", maxWidth: "700px", width: "100%" }}
        >
          <ModalHeader toggle={() => setModel1(!model1)}>
            user Report
          </ModalHeader>
          <ModalBody>
            <div className="view_report">
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    border: "1px solid #e9e9ef",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="center"> item Name</TableCell>

                      <TableCell align="center">check status</TableCell>
                      <TableCell align="center">Available</TableCell>
                    </TableRow>
                  </TableHead>
                  {err ? (
                    <TableRow>
                      <TableCell align="center">Not Available</TableCell>
                    </TableRow>
                  ) : (
                    <TableBody>
                      {itemList.map((item, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                          className={
                            item.status
                              ? "itemlist_status_available"
                              : "itemlist_status_not_available"
                          }
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {item.objectname}
                          </TableCell>

                          <TableCell align="center">checked</TableCell>
                          <TableCell align="center">
                            {item.status ? "Available" : "Not Available"}
                          </TableCell>
                        </TableRow>
                      ))}
                      {markers.map((marker, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className={
                            marker.status
                              ? "itemlist_status_available"
                              : "itemlist_status_not_available"
                          }
                        >
                          <TableCell component="th" scope="row">
                            {index + itemList.length + 1}
                          </TableCell>
                          <TableCell align="center">
                            {marker.objectname}
                          </TableCell>

                          <TableCell align="center">checked</TableCell>
                          <TableCell align="center">
                            {marker.status ? "Available" : "Missing"}
                          </TableCell>
                        </TableRow>
                      ))}

                      {tags.map((tag, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          className={
                            tag.status
                              ? "itemlist_status_available"
                              : "itemlist_status_not_available"
                          }
                          key={index}
                        >
                          <TableCell component="th" scope="row">
                            {index + itemList.length + markers.length + 1}
                          </TableCell>
                          <TableCell align="center">{tag.objectname}</TableCell>

                          <TableCell align="center">checked</TableCell>
                          <TableCell align="center">
                            {tag.status ? "Available" : "Missing"}
                          </TableCell>
                        </TableRow>
                      ))}
                      {fire_ext.map((fireTag, index) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={index}
                          className={
                            fireTag.status === "1"
                              ? "itemlist_status_available"
                              : "itemlist_status_not_available"
                          }
                        >
                          <TableCell component="th" scope="row">
                            {index +
                              itemList.length +
                              markers.length +
                              tags.length +
                              1}
                          </TableCell>
                          <TableCell align="center">
                            {fireTag.objectname}
                          </TableCell>

                          <TableCell align="center">checked</TableCell>
                          <TableCell align="center">
                            {fireTag.status === "1" ? "Valid" : "Expired"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}
export default OnlineUsers;
